.ob3__layout-grid__nav {
  background-color: black;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.ob3__layout-grid__nav--fixed {
  position: fixed;
}

.ob3__layout-grid__nav--transparent {
  background-color: transparent;
}

.ob3__layout-grid__nav__group {
  display: flex;
  justify-content: center;
}

/* First row in grid */
.ob3__layout-grid__nav__items {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  max-width: 992px;
  width: 100%;
}

@media (min-width: 1023px) {
  .ob3__layout-grid__nav__items {
    padding: 1rem 0;
  }
}

/* Rise logo in header */
.ob3__layout-grid__nav__brand {
  color: white;
}

.ob3__layout-grid__nav__btn {
  border: solid 2px var(--primary-purple);
  border-radius: 6px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 2;
  padding: 7px 12px;
  text-transform: uppercase;
}

@media (min-width: 735px) {
  .ob3__layout-grid__nav__btn {
    font-size: 16px;
    padding: 8px 32px;
  }
}
