.ob3__trust-graphic {
  display: block;
  margin-bottom: 16px;
}

.ob3__how-can-rise-help__background {
  background: radial-gradient(
    circle at 95% 95%,
    rgb(61, 118, 211),
    transparent 40%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ob3__how-can-rise-help__phone {
  bottom: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 60%;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__how-can-rise-help__background {
    background: radial-gradient(
      circle at 95% 85%,
      rgb(61, 118, 211),
      transparent 40%
    );
  }

  .ob3__how-can-rise-help__phone {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .ob3__how-can-rise-help__background {
    background: radial-gradient(
      circle at 95% 75%,
      rgb(61, 118, 211),
      transparent 40%
    );
  }

  .ob3__how-can-rise-help__phone {
    width: 40%;
  }
}
