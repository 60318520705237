.design-trial-checkout-screen-content {
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
}

.recommendedBar {
  padding-top: 50px;
}
.recommendedBar span {
  color: white;
  font-size: 11px;

  padding-right: 1em;
  padding-left: 1em;
  opacity: 0.5;
}
.recommendedLogos {
  height: 50px;
  opacity: 0.5;
  display: flex;
  padding-right: 1em;
  padding-left: 1em;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
