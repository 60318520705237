.ob3__devices__information {
  display: flex;
  align-items: flex-start;
}

.ob3__devices__information:first-of-type {
  margin-top: 16px;
}

.ob3__devices__information:not(:last-of-type) {
  margin-bottom: 16px;
}

.ob3__devices__information__icon-container {
  width: 40px;
}

.ob3__devices__information__copy {
  color: white;
  flex: 1;
  font-size: 12px;
  line-height: 1.4;
  margin-left: 8px;
}

@media (min-width: 735px) {
  .ob3__devices__information__copy {
    font-size: 15px;
  }
}
