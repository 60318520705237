.trial-selection-title {
  font-size: 13px;
  /* line-height: 24px; */
  color: #fff;
  padding-left: 12px;
  padding-right: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.student {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 0 3px;
  margin-top: 4px;
  margin-bottom: 4px;
  max-width: 300px;
  background-color: #00b38b;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.parent {
  display: flex;
  min-height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #000;
}
