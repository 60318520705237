.safe-full-screen {
  min-height: calc(100vh - 0rem);
}
.content-container {
  height: calc(100vh - 6rem);

  position: relative;
}

@supports (height: 100svh) {
  /* Accounts for the mobile tool bar in Safari and Chrome */
  /* https://opus.ing/posts/fixing-ios-safaris-menu-bar-overlap-css-viewport-units */
  .safe-full-screen {
    min-height: calc(100svh - 0rem);
  }

  .content-container {
    height: calc(100svh - 6rem);
  }
}
/* Yeah...it's a clunky slide show, but simple */
.slideContainer {
  transition: transform 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(100%);
}

.slideContainer.active {
  transform: translateX(0%);
}
.slideContainer.prev {
  transform: translateX(-100%);
}
.slideContainer.next {
  transform: translateX(100%);
}

.checkout-screen-content {
  position: relative;
  top: 47vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.gradientFadeOut {
  background: linear-gradient(to bottom, transparent, black);
  position: absolute;
  top: 72vh;
  left: 0;
  width: 100%;
  height: 100px;
}
