.progress-bar {
  background-color: var(--panel-gray-2);
  height: 6px;
  width: 100%;
}

.progress-bar__fill {
  background-image: linear-gradient(
    to right,
    var(--primary-purple),
    var(--orange-2)
  );
  height: 100%;
  transition: width 500ms ease-in;
  width: 10%;
}
