.header {
  margin-top: 1.75rem;
}

.tryFree {
  margin-top: 2.5rem;
}


@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .header {
      margin-top: 0rem;
  }

  .tryFree {
      margin-top: 0rem;
  }
}
