.gradient-trial-box {
  background: radial-gradient(1523.38% 172.52% at 57.03% 0%, #721cff 0%, #fd8206 100%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  max-height: 194px;
}
.gradient-trial-box-title {
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 18px;
  padding-right: 18px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.25em;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  text-wrap: nowrap;
}

.gradient-trial-box-subtitle {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 24px;
  padding-top: 14px;
  padding-bottom: 12px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  /* min-height:70px; */
  /* max-height:220px; */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
}
.gradient-trial-box-subtitle p {
  opacity: 0.7;
  padding-top: 8px;
}
