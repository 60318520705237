.ob3__brand-headline {
  color: var(--purple-2);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 6px;
  text-transform: uppercase;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__brand-headline {
    font-size: 19px;
  }
}

@media (min-width: 1024px) {
  .ob3__brand-headline {
    font-size: 21px;
  }
}

.ob3__brand-headline--secondary {
  color: var(--orange-2);
}

.ob3__brand-headline--ternary {
  color: var(--pink-1);
}

.ob3__large-headline {
  color: white;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 8px;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__large-headline {
    font-size: 42px;
  }
}

.ob3__medium-headline {
  color: white;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  .ob3__medium-headline {
    font-size: 19px;
  }
}

.ob3__body-copy {
  color: white;
  font-size: 14px;
  line-height: 1.5;
}

@media (min-width: 735px) {
  .ob3__body-copy {
    font-size: 17px;
  }
}

.ob3__gold-text {
  color: var(--yellow-1);
}

.ob3__purple-text {
  color: var(--purple-2);
}
