.ob3__gradient-phone {
  display: flex;
  justify-content: center;
  position: relative;
}

.ob3__gradient-phone__bg {
  max-width: 500%;
  position: absolute;
  bottom: 120px;
}

.ob3__gradient-phone__phone {
  position: relative;
  z-index: 100;
}

@media (max-width: 735px) {
  .ob3__gradient-phone__phone {
    width: 80%;
  }
}

@media (min-width: 735px) {
  .ob3__gradient-phone__bg {
    bottom: initial;
  }
}
