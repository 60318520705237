.ob3__testimonial {
  background-color: var(--panel-gray-2);
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 40px;
  max-width: 886px;
}

.ob3__testimonial__body {
  color: white;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.7;
}

.ob3__testimonial__user {
  align-items: center;
  display: flex;
  margin-top: 2.25rem;
}

.ob3__testimonial__user__details {
  display: flex;
  flex-direction: column;
}

.ob3__testimonial__user__img {
  margin-right: 1rem;
}

.ob3__testimonial__name,
.ob3__testimonial__personality {
  font-family: 'Work Sans';
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.ob3__testimonial__personality--early-riser {
  color: var(--orange-2);
}

.ob3__testimonial__personality--night-owl {
  color: var(--primary-purple);
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__testimonial {
    padding: 52px;
  }

  .ob3__testimonial__user {
    margin-top: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .ob3__testimonial {
    padding: 80px;
  }

  .ob3__testimonial__user {
    margin-top: 2.5rem;
  }

  .ob3__testimonial__body {
    font-size: 34px;
    font-weight: 400;
    max-width: 886px;
  }

  .ob3__testimonial__name {
    font-size: 24px;
    font-weight: 500;
  }
}
