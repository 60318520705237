.ob3__slide,
.ob3__slide--double-column {
  color: white;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding: 2rem 1rem 0 1rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__slide--double-column {
    padding: 3rem 1rem 2rem 1rem;
  }
}

@media (min-width: 1024px) {
  /** Make the area 1 columns on screens larger than breakpoint */
  .ob3__slide--double-column {
    display: grid;
    gap: 4rem;
    grid-template-columns: 3fr 2fr;
    margin: 0 auto;
    padding: 3rem 0 2rem 0;
    width: 992px;
  }

  .ob3__slide--double-column > :first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
