#choose-price-selector {
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 12px;
  justify-content: space-around;
}

#choose-price-selector > .option {
  background-color: #564573;
  background-size: contain;
  background-repeat: no-re peat;
  background-position: center;
  padding: 2px;
  border-radius: 12px;
  width: 108px;
  height: 120px;
  position: relative;
}

#choose-price-selector > .option.selected {
  background: linear-gradient(to right, #fd8206, #721cff);
}

#choose-price-selector > .option > .container {
  background-color: #1a063b;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10.5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#choose-price-selector > .option.selected > .container {
  background-color: #14052c;
}

.popular-badge {
  max-width: 100px;
  line-height: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  word-break: normal;
  position: absolute;
  top: -12px;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 24px;
  background-color: #564573;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
#choose-price-selector > .option.selected > .popular-badge {
  background: linear-gradient(to right, #fd8206, #721cff);
}

#choose-price-selector > .option > div > .price-container {
  font-size: 36px;
  font-weight: 500;
  color: white;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}
#choose-price-selector > .option.euro > div > .price-container {
  flex-direction: row-reverse;
  gap: 4px;
}

#choose-price-selector > .option.selected > div > .price-container {
  opacity: 1;
}

#choose-price-selector .price {
}

#choose-price-selector .currency {
  font-size: 18px;
  margin-top: 8px;
}

#choose-price-selector .trial-text {
  line-height: 1;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  font-size: 14px;
  color: white;
  opacity: 0.5;
}
#choose-price-selector .option.selected .trial-text {
  opacity: 1;
}
