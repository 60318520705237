.satisfaction-modal__content {
  background-color: var(--panel-gray-1);
  border-radius: 12px;
  padding: 2.5rem 1.25rem;
}

.satisfaction-modal__badge {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.satisfaction-modal__headline {
  color: white;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 40px;
  text-align: center;
}

.satisfaction-modal__copy {
  color: var(--gray-4);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

.satisfaction-modal__cta {
  background-color: var(--primary-purple);
  border: solid 1px var(--primary-purple);
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  padding: 20px 16px;
  margin-top: 8px;
  width: 100%;
}

.satisfaction-modal__dot {
  color: var(--primary-purple);
  margin-right: 0.5rem;
  position: relative;
  font-size: 30px;
  top: -0.46em;
}
