@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html,
body {
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer components {
  .badge {
    @apply text-white text-center border border-green-2 px-4 text-xs bg-green-1 rounded-xl w-auto inline-block;
  }
  .feature-list-item {
    @apply text-white flex items-center font-light;
  }
  .btn {
    @apply border border-purple-1 w-full max-w-xs bg-purple-1 p-3 text-white rounded-md font-medium tracking-wide;
  }
  .btn:hover {
    @apply border-white;
  }
  .btn:focus {
    @apply outline-none;
  }
}
@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}
