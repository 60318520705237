.hills-background {
  background-image: url('./assets/Bg3.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}

.five-days {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.list-header {
  color: #fd8206;
  font-size: 14px;
  line-height: 16px;
}

.list-info {
  font-size: 14px;
  color: #e0e0e0;
  font-weight: 400;
}

.sales-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
}

.logos {
  height: 53px;
}

.ob3__layout-grid__nav__items_taller {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  max-width: 992px;
  width: 100%;
}

.ob3__layout-grid__nav_not_fixed {
  background-color: black;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.timeline {
  position: absolute;
  left: 8px;
  top: 3em;
  z-index: 0;
  width: 2px;
  height: 245px;
}

.take-control {
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  line-height: 32.84px;
  margin-top: 0.3em;
}

.subtitle {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0.6em;
  margin-bottom: 6.2em;
}

.subtitleExperiment {
  margin-bottom: 8.2em;
}

.titleSurvey {
  margin-bottom: 2em !important;
  text-align: start;
}

.subtitleSurvey {
  font-weight: 900;
  text-align: start;
}

.titleParent {
  font-weight: 400 !important;
}

.with-rise {
  font-size: 21px;
  font-weight: 700;
}

.riseLogo {
  height: 64px;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}

/* rough estimation of desktop */
@media (min-width: 500px) {
  .hills-background {
    max-width: 640px;
    background-image: url('./assets/Bg3.png');
    background-repeat: no-repeat;
    background-position: center -166px;
    background-size: cover;
    height: 100%;
  }

  .subtitle {
    margin-bottom: 7.5em;
  }

  .subtitleExperiment {
    margin-bottom: 9.5em;
  }

  .with-rise {
    font-size: 21px;
    font-weight: 700;
  }

  .five-days {
    margin-top: 25px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 600;
  }
}

/* 2532x1170 pixels at 460ppi 12 pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .hills-background {
    max-width: 640px;
    background-image: url('./assets/Bg3.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 10px;
    height: 100%;
  }

  .subtitle {
    margin-bottom: 5.5em;
  }

  .subtitleExperiment {
    margin-bottom: 7.7em;
  }

  .with-rise {
    font-size: 21px;
    font-weight: 700;
  }

  .take-control {
    font-size: 26px;
    text-align: center;
    font-weight: 600;
    line-height: 32.84px;
  }
}

/* 2436x1125px at 458ppi iPhone X */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .hills-background {
    background-image: url('./assets/Bg3.png');
    background-repeat: no-repeat;
    background-position: center 30px;
    background-size: contain;
  }
}

/*iPhone SE 6/7/8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  .hills-background {
    background-image: url('./assets/Bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto;
  }

  .logos {
    height: 50px;
  }

  .ob3__layout-grid__nav__items_taller {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    max-width: 992px;
    width: 100%;
  }
}

/* iPhone 5/SE */
@media screen and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .hills-background {
    background-image: url('./assets/Bg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto;
  }

  .subtitle {
    margin-bottom: 5em;
  }

  .subtitleExperiment {
    margin-bottom: 7em;
  }

  .timeline {
    position: absolute;
    left: 8px;
    top: 5em;
    z-index: 0;
    width: 2px;
    height: 300px;
  }

  .ob3__layout-grid__nav__items_taller {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    max-width: 992px;
    width: 100%;
  }

  .take-control {
    font-size: 24px;
  }

  .sales-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 22.4px;
  }
  .logos {
    height: 47px;
  }

  .five-days {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 600;
  }
}

/*iPhone 6/7/8+ */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (device-width: 414px) and (device-height: 736px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .hills-background {
    background-size: cover;
  }
}
