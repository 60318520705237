.bgGradient {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 15%,
      rgba(0, 0, 0, 1) 95%
    ),
    radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(225deg, #fd8206 0.62%, #721cff 76.34%);

  background-size: 1440 657px, 1440 auto;
  background-repeat: no-repeat;
  background-position: center top;
}

.bgHero {
  background-image: url('../images/hero@2x.png');
  background-size: 819px 657px;
  background-repeat: no-repeat;
  background-position: center top;
}
.bgFade {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100px,
    rgba(0, 0, 0, 1) 600px,
    rgba(0, 0, 0, 1) 100%
  );

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
}
.bgHeroSun {
  background-image: url('../images/paywall_background.png');
  background-size: 819px 657px;
  background-repeat: no-repeat;
  background-position: center top;
}


.bgHeroPhone {
  background: linear-gradient(
          180deg,
          black,
          transparent 30%
  ), linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 100px,
          rgba(0, 0, 0, 1) 600px,
          rgba(0, 0, 0, 1) 100%
  ), url('../images/background_maybe.png');
  background-repeat: no-repeat;
  background-position: 93% 39%;
}



.fadeUp {
  background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 100px,
          rgba(0, 0, 0, 1) 600px,
          rgba(0, 0, 0, 1) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.fadeDown {
  background: linear-gradient(0deg, black, transparent);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.email-input::placeholder {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    font-family: 'Arial', sans-serif;
}
