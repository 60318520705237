/** Styles applied to the modal */

.modal__overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
}

.modal_content {
  flex: 1;
  margin: 0 1.5rem;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .modal_content {
    margin: 0 auto;
    max-width: 80%;
  }
}

@media (min-width: 1024px) {
  .modal_content {
    margin: 0 auto;
    max-width: 400px;
  }
}
