.slide {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: radial-gradient(
    198.15% 137.2% at 50% 135.71%,
    #fd8206 26.01%,
    #721cff 60.42%,
    #222229 82.38%
  );
  /* card shadow */
  box-shadow: 0px 0px 10px #000000;
  border-radius: 12px;
  margin: 8px;
}

.slideImage {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  z-index: 0;
  /* object-fit: cover; */
}
.icon {
}
.calloutContainer {
}
