.ob3__image-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 735px) and (max-width: 1024px) {
  .ob3__image-container {
    max-width: 359px;
  }
}
