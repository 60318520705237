/* Final row in grid */
.ob3__layout-grid__footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.ob3__layout-grid__footer__row {
  align-items: center;
  background-color: black;

  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 1024px) {
  .ob3__layout-grid__footer__row {
    padding: 2rem;
  }
}

.ob3__layout-grid__footer__btn {
  border: solid 1px var(--primary-purple);
  border-radius: 12px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.1;
  padding: 12px 16px;
}

.ob3__layout-grid__footer__btn--outline--disabled {
  border-color: var(--panel-gray-2);
  color: var(--panel-gray-2);
}

.ob3__layout-grid__footer__btn--solid {
  background-color: var(--primary-purple);
}

.ob3__layout-grid__footer__btn:first-of-type {
  margin-right: 1rem;
}
