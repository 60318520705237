.ob3__law-sidebar {
  background-color: var(--panel-gray-2);
  border-radius: 12px;
  padding: 32px 16px;
}

.ob3__law-sidebar:not(:last-of-type) {
  margin-bottom: 20px;
}

@media (min-width: 735px) and (max-width: 1023px) {
  .ob3__law-sidebar {
    flex: 1;
  }

  .ob3__law-sidebar:first-of-type {
    margin-right: 20px;
  }

  .ob3__law-sidebar:not(:last-of-type) {
    margin-bottom: 0px;
  }
}

@media (min-width: 1024px) {
  .ob3__law-sidebar {
    padding: 40px;
  }
}

.ob3__law-sidebar__law {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
}

.ob3__law-sidebar__number {
  font-size: 84px;
  font-weight: 500;
  line-height: 1.1;
  width: 60px;
}

.ob3__law-sidebar__label {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
}

.ob3__law-sidebar__copy {
  color: white;
  font-size: 14px;
  line-height: 1.5;
}
