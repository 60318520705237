/** Styles for the Onboarding v3 layout */

:root {
  --primary-purple: #721cff;
  --purple-2: #8e58ff;
  --panel-gray-1: #16161a;
  --panel-gray-2: #222229;

  --gray-1: #333333; /* rgb(51, 51, 51) */
  --gray-2: #4f4f4f; /* rgb(79, 79, 79) */
  --gray-4: #bdbdbd; /* rgb(189, 189, 189) */
  --orange-2: #ff9900; /* rgb(255, 153, 0) */
  --yellow-1: #ffb800; /* rgb(255, 184, 0) */
  --pink-1: #fa607b; /* rgb(250, 96, 123) */
}

.ob3__layout__main {
  background: linear-gradient(180deg, #000000 0%, #111111 100%);
  padding-bottom: 7rem;
  padding-top: 5rem;
  overflow: hidden;
  min-height: 100vh;
}

@media (min-width: 1024px) {
  .ob3__layout__main {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
}
