.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: flex-start;
  align-items: cente;
  min-height: 50px;
  width: '100%';
}
.list-item .icon > img {
  width: 36px;
  max-height: 36px;
}
.list-item .icon {
  min-width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item > p {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #e0e0e0;
}
