.cell--bodyContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;

  min-height: 58px;
  text-align: start;
  padding: 12px 20px 12px 12px;
}

.cell--container {
  width: 100%;
  border-bottom: 1px solid black;

  display: flex;
  flex-direction: row;
}

.cell--subtitle {
  color: #d4d4d4;
  font-size: 16px;
  line-height: 18px;
}

.cell--title {
  font-size: 20px;
  line-height: 18px;
  font-size: medium;
  font-weight: bold;
  color: #ffffff;
}
