.keyword-greeting {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: black;
  background-image: url('../onboarding-v3/assets/greeting-sleep-tracking-background.png');
  background-position-y: -40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.keyword-greeting--sleep-tracking {
  background-image: url('../onboarding-v3/assets/greeting-sleep-tracking-background.png');
}

.keyword-greeting--watch {
  background-image: url('../onboarding-v3/assets/greeting-watch-background.png');
}

.keyword-greeting--fall-asleep-faster {
  background-image: url('../onboarding-v3/assets/greeting-fall-asleep-faster-background.png');
  background-position-y: 0px;
}

@media (min-width: 426px) and (max-width: 735px) {
  .keyword-greeting {
    background-size: 80%;
    background-position: center;
    background-position-y: 10%;
  }
}

@media (min-width: 735px) and (max-width: 1023px) {
  .keyword-greeting {
    background-size: 55%;
    background-position: center;
    background-position-y: 10%;
  }
}

@media (min-width: 1024px) and (max-width: 1339px) {
  .keyword-greeting {
    background-size: 45%;
    background-position: center;
    background-position-y: 20%;
  }
}

@media (min-width: 1440px) {
  .keyword-greeting {
    background-size: 40%;
    background-position: center;
    background-position-y: 20%;
  }
}

.keyword-greeting__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 992px;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  position: relative;
}

@media (min-width: 1024px) {
  .keyword-greeting__container {
    padding-left: 0;
    padding-right: 0;
  }
}

.keyword-greeting__main {
  margin-top: 300px;
}

@media (max-width: 1024px) {
  .keyword-greeting__main {
    padding-bottom: 96px;
  }
}

@media (min-width: 375px) {
  .keyword-greeting__main {
    margin-top: 340px;
  }
  .keyword-greeting--fall-asleep-faster .keyword-greeting__main {
    margin-top: 390px;
  }
}

@media (min-width: 425px) {
  .keyword-greeting__main {
    margin-top: 420px;
  }
  .keyword-greeting--fall-asleep-faster .keyword-greeting__main {
    margin-top: 440px;
  }
}

@media (min-width: 735px) {
  .keyword-greeting__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    margin-top: 0px;
  }

  .keyword-greeting--fall-asleep-faster .keyword-greeting__main {
    margin-top: 0px;
  }
}

.keyword-greeting__main__headline {
  color: white;
  font-family: 'Work Sans';
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0.5rem;
}

.keyword-greeting__graphics {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}

.keyword-greeting__graphics__icon {
  border-radius: 12px;
  height: 36px;
  margin-right: 10px;
  width: 36px;
}

.keyword-greeting__main__body {
  color: white;
  font-family: 'Work Sans';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 1024px) {
  .keyword-greeting__cta-container {
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
  }
}

.keyword-greeting__cta-container__gradient {
  background-image: linear-gradient(transparent, #0e0e11);
  height: 24px;
  width: 100%;
}

.keyword-greeting__cta-container__cta {
  background-color: #0e0e11;
  display: flex;
}

.keyword-greeting__cta-container__cta__btn {
  background-color: var(--primary-purple);
  border-radius: 12px;
  color: white;
  flex: 1;
  margin: 6px 12px 12px 12px;
  padding: 1rem 0;
  text-transform: uppercase;
}

.full-height {
  height: 100%;
}
